import React from 'react';
import classNames from 'classnames/bind';
import type { EventTeam } from '../../../../../types';
import style from './EventgroupsModal.module.scss';

const cx = classNames.bind(style);

type EventgroupsModalProps = {
  eventGroups: EventTeam[][][];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close: (e?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reload: (e?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send: (e?: any) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function EventgroupsModal({ eventGroups, ...props }: EventgroupsModalProps): JSX.Element {
  const ModalBaseClasses = cx({ ModalBase: true });
  const ModalClasses = cx({ Modal: true });
  const ModalHeaderClasses = cx({ ModalHeader: true });

  return (
    <div className={ModalBaseClasses}>
      <div className={ModalClasses}>
        <div className={'w-100 flex flex-end'}>
          <span
            style={{ fontSize: '2rem', fontWeight: 'bold', cursor: 'pointer' }}
            className={'mr-2'}
            onClick={props.send}
          >
            ✅
          </span>
          <span
            style={{ fontSize: '2rem', fontWeight: 'bold', cursor: 'pointer' }}
            className={'mr-2'}
            onClick={props.reload}
          >
            🔄
          </span>
          <span style={{ fontSize: '2rem', fontWeight: 'bold', cursor: 'pointer' }} onClick={props.close}>
            ✖️
          </span>
        </div>
        <div className={ModalHeaderClasses}>Eventgruppen</div>
        <div>
          {eventGroups.map((eventRoundGroups, round) => (
            <div key={round}>
              <div style={{ fontSize: '2rem', fontWeight: 'bold' }} className={'mt-2 mb-1'}>
                Runde {round + 1}
              </div>
              {eventRoundGroups.map((groups, group) => (
                <div style={{ lineHeight: '1.5' }} className={'pl-1'} key={group}>
                  <span style={{ fontSize: '1.4rem' }}>
                    <span style={{ fontWeight: 'bold' }}>Gruppe {group + 1}: </span>
                    {groups.map((team, teamIndex) => team.team + (teamIndex !== groups.length - 1 ? ', ' : ''))}
                    <span> - Ausrichter: {groups[0].team}</span>
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
