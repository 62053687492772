import React from 'react';
import classNames from 'classnames/bind';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/reducers';
import { NavigationDrawer } from '../../organisms';
import style from './Dashboard.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Dashboard(): JSX.Element {
  const user = useAppSelector(selectUser);

  const pageClasses = cx('container');

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <h1>Willkommen {user?.name}</h1>
      </div>
    </div>
  );
}
