import React from 'react';
import classNames from 'classnames/bind';
import style from './Button.module.scss';

const cx = classNames.bind(style);

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  disabled?: boolean;
  type: 'primary' | 'secondary';
  testId?: string;
};

export function Button({ children, type, disabled = false, ...props }: ButtonProps): JSX.Element {
  const ButtonClasses = cx(
    {
      Button: true,
      [`Button-${type}`]: true,
    },
    props.className
  );

  return (
    <button className={ButtonClasses} onClick={props.onClick} disabled={disabled} test-id={props.testId}>
      {children}
    </button>
  );
}
