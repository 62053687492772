import React, { useEffect, useState } from 'react';
import { isExpired } from 'react-jwt';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import Logo from '../../../assets/images/logo.png';
import { UserService } from '../../../services';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { login, selectIsAuthenticated } from '../../../store/reducers';
import { Button } from '../../atoms';
import { Input } from '../../molecules';
import style from './Login.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Login(): JSX.Element {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const [password, setPassword] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem('jwt');

    if (token && !isExpired(token)) {
      dispatch(login({ user: { name: 'Admin' }, token, isAuthenticated: true }));
    }

    if (isAuthenticated || (token && !isExpired(token))) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  }, []);

  const handleLogin = async (): Promise<void> => {
    const authData = {
      password,
    };
    const loginResponse = await UserService.login(authData);
    if (loginResponse.error) {
      console.log(loginResponse);
      if (
        loginResponse.message.response.data.error === 'email' ||
        loginResponse.message.response.data.error === 'password'
      ) {
        toast.error('Logindaten sind fehlerhaft.');
      } else {
        toast.error('Etwas ist schief gelaufen.');
      }
    }

    if (!loginResponse.error) {
      const userData = {
        name: 'Admin',
      };
      sessionStorage.setItem('jwt', loginResponse.data.token);
      dispatch(
        login({
          user: userData,
          token: loginResponse.data.token,
          isAuthenticated: true,
        })
      );
      setPassword('');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleLogin();
  };

  const LoginPageClasses = cx({ Login: true });
  const LoginBoxClasses = cx({ LoginBox: true });
  const LogoContainerClasses = cx('margin-10');
  const InputClasses = cx('margin-10');
  const ButtonClasses = cx('margin-10');

  return (
    <div className={LoginPageClasses}>
      <div className={LoginBoxClasses}>
        <div className={LogoContainerClasses}>
          <img src={Logo} alt='Studentendinner Logo' width={200} />
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            onChange={(event) => setPassword(event.target.value)}
            className={InputClasses}
            type='password'
            icon='magnify'
            placeholder='Passwort...'
            size={15}
            testId='login-input-password'
          />
          <Button className={ButtonClasses} type='primary' onClick={handleLogin} testId='login-button-login'>
            Login
          </Button>
        </form>
      </div>
    </div>
  );
}
