import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import Logo from '../../../assets/images/logo.png';
import { NavigationItem } from '../../molecules';
import style from './NavigationDrawer.module.scss';

const cx = classNames.bind(style);

export function NavigationDrawer(): JSX.Element {
  const location = useLocation();
  const paths = location.pathname.split('/');

  const containerClasses = cx({ NavigationDrawerContainer: true });
  const itemsClasses = cx({ NavigationDrawerItems: true });
  const spacerClasses = cx({ NavigationDrawerSpacer: true });

  return (
    <div className={containerClasses}>
      <img src={Logo} alt='FourEnergy Logo' width={220} />
      <div className={itemsClasses}>
        <NavigationItem link={'/dashboard'} active={paths[1] === 'dashboard'}>
          Dashboard
        </NavigationItem>
        <div className={spacerClasses} />
        <NavigationItem link={'/events'} active={paths[1] === 'events'}>
          Events
        </NavigationItem>
        <NavigationItem link={'/sponsoren'} active={paths[1] === 'sponsoren'}>
          Sponsoren
        </NavigationItem>
        <NavigationItem link={'/user'} active={paths[1] === 'user'}>
          User
        </NavigationItem>
        <NavigationItem link={'/logout'}>Logout</NavigationItem>
      </div>
    </div>
  );
}
