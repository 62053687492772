import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { EventService } from '../../../services';
import type { Event } from '../../../types';
import { Button, Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import style from './Events.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Events(): JSX.Element {
  const navigate = useNavigate();

  const [events, setEvents] = useState<Event[]>([]);

  const getDate = (eventDate: string) => {
    const date = new Date(eventDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    EventService.getAllEvents().then((response) => {
      if (response.error) {
        toast.error('Events konnten nicht geladen werden!');

        return;
      }
      const data = response.data as Event[];
      if (data) {
        data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }
      setEvents(data);
    });
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const actionBarClasses = cx('flex', 'flex-end', 'mb-2');
  const eventBoxClasses = cx({ EventBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses}>
          <Icon icon='kontakt' size={70}></Icon>
          <span>
            <h1>Events</h1>
            <div>Anzahl: {events.length}</div>
          </span>
        </div>
        <div className={actionBarClasses}>
          <div>
            <Button type='primary' onClick={() => navigate('/events/new')}>
              Event hinzufügen
            </Button>
          </div>
        </div>
        {events.map((event) => (
          <div key={event.id} className={eventBoxClasses} onClick={() => navigate(`/events/${event.id}`)}>
            <div>
              <span>{getDate(event.date)}</span>
            </div>
            <div>{event.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
