import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { EventService } from '../../../../services';
import type { Address, Event, EventTeam } from '../../../../types';
import { Button, Icon, Text } from '../../../atoms';
import { NavigationDrawer } from '../../../organisms';
import { EventgroupsModal } from './EventGroupsModal/EventgroupsModal';
import style from './Event.module.scss';

const cx = classNames.bind(style);

export function Event(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  const [event, setEvent] = useState<Event>();
  const [eventTeams, setEventTeams] = useState<EventTeam[]>();
  const [eventAddress, setEventAddress] = useState<Address>();
  const [eventGroups, setEventGroups] = useState<EventTeam[][][] | undefined>(undefined);

  const getDate = (eventDate: string) => {
    const date = new Date(eventDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  const deleteEvent = () => {
    if (params.id) {
      EventService.deleteEvent(params.id).then((response) => {
        if (response.error) {
          toast.error('Event konnte nicht gelöscht werden!');

          return;
        }
        toast.success('Event wurde erfolgreich gelöscht!');
        navigate('/events');
      });
    }
  };

  const getEventGroups = () => {
    if (params.id) {
      EventService.getEventGroupsByEventId(params.id).then((response) => {
        setEventGroups(response.data as EventTeam[][][]);
      });
    }
  };

  const sendEventGroupsMails = () => {
    if (params.id && eventGroups) {
      EventService.sendEventGroupsMails(params.id, eventGroups).then((response) => {
        if (response.error) {
          toast.error('Mails konnten nicht versendet werden!');

          return;
        }
        setEventGroups(undefined);
        toast.success('Mails wurden erfolgreich an die Teilnehmer versendet!');
      });
    }
  };

  const removeTeam = (teamId: string) => {
    EventService.removeTeamFromEvent(teamId).then((response) => {
      if (response.error) {
        toast.error('Team konnte nicht entfernt werden!');

        return;
      }
      const newEventTeams = eventTeams?.filter((team) => team.id !== teamId);
      setEventTeams(newEventTeams);
      toast.success('Team wurde erfolgreich entfernt!');
    });
  };

  useEffect(() => {
    if (params.id) {
      EventService.getEventById(params.id).then((response) => {
        if (response.error) {
          toast.error('Event konnte nicht geladen werden!');

          return;
        }
        setEvent(response.data);
      });

      EventService.getEventAddressByEventId(params.id).then((response) => {
        if (response.error) {
          toast.error('Event Adresse konnte nicht geladen werden!');

          return;
        }
        setEventAddress(response.data);
      });

      EventService.getEventTeamsByEventId(params.id).then((response) => {
        if (response.error) {
          toast.error('EventTeams konnte nicht geladen werden!');

          return;
        }
        const teams = response.data as EventTeam[];
        teams.sort((a, b) => a.team - b.team);
        setEventTeams(teams);
      });
    }
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const actionBarClasses = cx('flex', 'flex-end', 'mb-2');
  const boxHeaderClasses = cx({ BoxHeader: true });
  const boxInnerHeaderClasses = cx({ BoxInnerHeader: true });
  const eventBoxClasses = cx({ EventBox: true });
  const eventTeamBoxClasses = cx({ EventBox: true, EventBoxTeam: true });

  return (
    <>
      {eventGroups && (
        <EventgroupsModal
          eventGroups={eventGroups}
          close={() => setEventGroups(undefined)}
          reload={() => getEventGroups()}
          send={() => sendEventGroupsMails()}
        />
      )}
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses}>
            <Icon icon='kontakt' size={70}></Icon>
            <span>
              <h1>{event ? event.title : ''}</h1>
              <div></div>
            </span>
          </div>
          <div className={actionBarClasses}>
            <div className={'mr-2'}>
              <Button type='primary' onClick={() => getEventGroups()}>
                Eventgruppen erstellen
              </Button>
            </div>
            <div className={'mr-2'}>
              <Button type='primary' onClick={() => navigate(`edit`)}>
                Event bearbeiten
              </Button>
            </div>
            <div>
              <Button type='primary' onClick={() => deleteEvent()}>
                Event löschen
              </Button>
            </div>
          </div>
          <div>
            <h2 className={boxHeaderClasses}>Eventinfos</h2>
            {event && (
              <div className={eventBoxClasses}>
                <div>
                  <span>{getDate(event.date)}</span>
                </div>

                <div className={boxInnerHeaderClasses}>Stadt des Events:</div>
                <div>
                  <Text>{(event.city[0].toUpperCase() + event.city.slice(1)).replaceAll('ue', 'ü')}</Text>
                </div>

                <div className={boxInnerHeaderClasses}>Adresse:</div>
                <div>
                  {eventAddress && (
                    <>
                      <Text>{eventAddress?.street}</Text>
                      <Text>
                        {eventAddress?.postalcode} {eventAddress?.city}
                      </Text>
                    </>
                  )}
                  {!eventAddress && <Text>-</Text>}
                </div>

                <div className={boxInnerHeaderClasses}>Beschreibung des Events:</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <Text>{event.description}</Text>
                </div>
              </div>
            )}
          </div>

          <div>
            <h2 className={boxHeaderClasses}>Teams ({eventTeams?.length})</h2>

            {eventTeams?.length === 0 && (
              <div className={eventTeamBoxClasses}>
                <Text>Es hat sich noch kein Team registriert</Text>
              </div>
            )}
            {eventTeams &&
              eventTeams.map((team) => (
                <div className={eventTeamBoxClasses} key={team.id}>
                  <div>
                    <span>Angemeldet: {getDate(team.createdAt)}</span>
                    <span onClick={() => removeTeam(team.id)}>🗑️</span>
                  </div>
                  <div>
                    <Text>
                      {team.team} - {team.user.firstname} {team.user.lastname}
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
