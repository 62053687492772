import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { SponsorService } from '../../../../services';
import type { Sponsor } from '../../../../types';
import { Button, Icon, Text } from '../../../atoms';
import { NavigationDrawer } from '../../../organisms';
import style from './Sponsor.module.scss';

const cx = classNames.bind(style);

export function Sponsor(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  const [sponsor, setSponsor] = useState<Sponsor>();

  const deleteSponsor = () => {
    if (params.id) {
      SponsorService.deleteSponsor(params.id).then((response) => {
        if (response.error) {
          toast.error('Sponsor konnte nicht gelöscht werden!');

          return;
        }
        toast.success('Sponsor wurde erfolgreich gelöscht!');
        navigate('/sponsoren');
      });
    }
  };

  useEffect(() => {
    if (params.id) {
      SponsorService.getSponsorById(params.id).then((response) => {
        if (response.error) {
          toast.error('Sponsor konnte nicht geladen werden!');

          return;
        }
        setSponsor(response.data);
      });
    }
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const actionBarClasses = cx('flex', 'flex-end', 'mb-2');
  const boxHeaderClasses = cx({ BoxHeader: true });
  const sponsorBoxClasses = cx({ SponsorBox: true });

  return (
    <>
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses}>
            <Icon icon='premium-check' size={70}></Icon>
            <span>
              <h1>{sponsor ? sponsor.name : ''}</h1>
              <div></div>
            </span>
          </div>
          <div className={actionBarClasses}>
            <div className={'mr-2'}>
              <Button type='primary' onClick={() => navigate(`edit`)}>
                Sponsor bearbeiten
              </Button>
            </div>
            <div>
              <Button type='primary' onClick={() => deleteSponsor()}>
                Sponsor löschen
              </Button>
            </div>
          </div>
          <div>
            <h2 className={boxHeaderClasses}>Beschreibung:</h2>
            {sponsor && (
              <div className={sponsorBoxClasses}>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <Text>{sponsor.description}</Text>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
