import type { ReactNode } from 'react';
import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ProtectedPage } from '../components/atoms';
import {
  Dashboard,
  EditEvent,
  EditSponsor,
  Event,
  Events,
  Login,
  NewEvent,
  NewSponsor,
  Sponsor,
  Sponsors,
  User,
  Users,
} from '../components/pages';

type RouterProps = {
  children?: ReactNode;
};
export function Router({ children }: RouterProps): JSX.Element {
  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route
          path='dashboard'
          element={
            <ProtectedPage>
              <Dashboard />
            </ProtectedPage>
          }
        />
        <Route
          path='events'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Events />} />
          <Route path='new' element={<NewEvent />} />
          <Route path=':id' element={<Outlet />}>
            <Route path='' element={<Event />} />
            <Route path='edit' element={<EditEvent />} />
          </Route>
        </Route>
        <Route
          path='sponsoren'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Sponsors />} />
          <Route path='new' element={<NewSponsor />} />
          <Route path=':id' element={<Outlet />}>
            <Route path='' element={<Sponsor />} />
            <Route path='edit' element={<EditSponsor />} />
          </Route>
        </Route>
        <Route
          path='user'
          element={
            <ProtectedPage>
              <Outlet />
            </ProtectedPage>
          }
        >
          <Route path='' element={<Users />} />
          <Route path=':id' element={<User />} />
        </Route>
        <Route path='login' element={<Login />} />
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<h1>Actual no Page</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
