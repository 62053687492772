import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export class UserService {
  static async login(data: { password: string }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/authenticate-admin', { ...data })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAllUsers(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/user', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getUserById(id: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/user/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
