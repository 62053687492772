import axios from 'axios';
import type { Address, Event, EventTeam } from '../types';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export class EventService {
  static async createEvent(data: Event & { address: Address }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/event', data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async uploadEventPicture(data: FormData): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/event/image', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAllEvents(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/event')
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getEventById(id: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/event/${id}`)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getEventTeamsByEventId(id: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/event/${id}/teams`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getEventAddressByEventId(id: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/event/${id}/address`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async updateEvent(data: Event & { address: Address }): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put(`/event`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async deleteEvent(id: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .delete(`/event/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getEventGroupsByEventId(id: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get(`/event/${id}/groups`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async sendEventGroupsMails(id: string, eventGroups: EventTeam[][][]): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post(`/event/${id}/groups`, eventGroups, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async removeTeamFromEvent(teamId: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .delete(`/event/team/${teamId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
