import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface AuthState {
  isAuthenticated: boolean;
  token: string | undefined;
  user: { name: string } | undefined;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: undefined,
  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthState>) => {
      const { token, user } = action.payload;
      /* eslint-disable */
            state.isAuthenticated = true;
            state.token = token;
            state.user = user;
            /* eslint-enable */
    },
    logout: (state) => {
      /* eslint-disable */
            state.isAuthenticated = false;
            state.token = undefined;
            state.user = undefined;
            /* eslint-enable */
    },
  },
});

export const { login, logout } = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
