import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { SponsorService } from '../../../../services';
import type { Sponsor } from '../../../../types';
import { Button, Icon } from '../../../atoms';
import { Input } from '../../../molecules';
import { NavigationDrawer } from '../../../organisms';
import style from './NewSponsor.module.scss';

const cx = classNames.bind(style);
export function NewSponsor(): JSX.Element {
  const navigate = useNavigate();
  const [newSponsor, setNewSponsor] = useState<Sponsor>({
    name: '',
    description: '',
    picture: '',
  });
  const [sponsorPicture, setSponsorPicture] = useState<File>();

  const createSponsor = () => {
    const formData = new FormData();

    formData.append('file', sponsorPicture as Blob);
    SponsorService.uploadSponsorPicture(formData).then((response) => {
      if (response.error) {
        toast.error('Upload des Sponsorbildes fehlgeschlagen!');

        return;
      }
      newSponsor.picture = response.data.filePath;
      SponsorService.createSponsor(newSponsor).then((res) => {
        if (res.error) {
          toast.error('Sponsor konnte nicht erstellt werden!');

          return;
        }

        toast.success('Sponsor wurde erfolgreich erstellt!');
        navigate('/sponsoren');
      });
    });
  };

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const sponsorBoxClasses = cx({ SponsorBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses}>
          <Icon icon='premium-check' size={70}></Icon>
          <span>
            <h1>Neuer Sponsor</h1>
            <div></div>
          </span>
        </div>

        <div className={sponsorBoxClasses}>
          <div>
            <div>Name:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Name'
                onChange={(event) => {
                  setNewSponsor({ ...newSponsor, name: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Bild:</div>
            <div>
              <Input
                icon={'none'}
                type='file'
                placeholder='Bild'
                onChange={(event) => {
                  if (event.target.files) {
                    setSponsorPicture(event.target.files[0]);
                  }
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Beschreibung:</div>
            <div>
              <textarea
                rows={20}
                style={{ width: '100%' }}
                value={newSponsor.description}
                onChange={(event) => setNewSponsor({ ...newSponsor, description: event.target.value })}
              ></textarea>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: '5rem' }}>
          <Button type='primary' onClick={createSponsor}>
            Sponsor erstellen
          </Button>
        </div>
      </div>
    </div>
  );
}
