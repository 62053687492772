import { IconDefaults } from '../IconDefaults';
import type { IconProps } from '../IconProps';

export function PremiumCheck({ size = IconDefaults.size, color = IconDefaults.color }: IconProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox='0 0 35 35' fill={color}>
      <g id='05-Apotheke-wählen' stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <g id='premium-icon' fill={color}>
          <path
            d='M17.5052922,0.000728001804 C18.3679603,-0.0158572849 19.2125164,0.251335499 19.9111919,0.76188412 C20.8589705,1.36734921 21.9096754,2.55665565 23.2176957,3.30916226 C25.0532127,4.36872618 28.4583541,2.90696045 30.2038107,5.51910986 C31.2202069,7.04142209 31.2673814,8.23505328 31.344576,9.41138546 C31.3989343,10.9406076 31.9607217,12.4071741 32.9399319,13.5761204 C35.0842275,16.4304558 35.5130866,18.3333461 34.4194959,20.3097571 C33.6689924,21.6547546 32.0865023,22.4072612 31.721972,23.2592371 C30.9414484,25.0756324 31.8034553,26.4465784 30.735596,28.5657062 C30.0555828,29.9774378 28.8233414,31.0398639 27.3347432,31.4978871 C26.0481659,31.9303622 24.7615885,31.3119229 23.7366152,31.7487227 C21.9311183,32.5185283 20.5973665,34.2960008 19.1435341,34.7457749 C18.0640687,35.0847417 16.9079184,35.0847417 15.8284531,34.7457749 C14.391775,34.3132998 13.0623118,32.5185283 11.2568149,31.7530474 C10.227553,31.3205724 8.94097562,31.9217127 7.65868686,31.5022119 C6.16719568,31.0476019 4.93241098,29.9843238 4.25354546,28.5700309 C3.18568625,26.4509031 4.04769308,25.0799571 3.26716948,23.2635619 C2.90263923,22.3986117 1.32014908,21.6590794 0.569645624,20.3140819 C-0.515367947,18.3333461 -0.0693544629,16.4347805 2.07065254,13.5804451 C3.04641541,12.4094415 3.60772647,10.9441184 3.66600846,9.41571021 C3.73891451,8.23505328 3.78608902,7.04142209 4.80677372,5.52343461 C6.54794174,2.92858421 9.95308315,4.37305093 11.7928888,3.31348702 C13.0794661,2.5609804 14.1559025,1.37167396 15.095104,0.770533621 C15.7943178,0.256865174 16.640326,-0.0133468523 17.5052922,0.000728001804 Z M17.4730184,5.77112449 L17.4531508,5.77112449 C10.8276736,5.77386682 5.45811776,11.1456488 5.45811776,17.7711245 C5.45811776,24.3966078 10.8276814,29.7683845 17.453157,29.7711245 C24.0786326,29.7738624 29.4526306,24.406527 29.4581178,17.7810505 C29.4635965,11.1555741 24.0984894,5.77935149 17.4730184,5.77112449 L17.4730184,5.77112449 Z'
            id='Shape'
            fillRule='nonzero'
          ></path>
          <polygon
            id='Path'
            points='16.6692965 24 10 17.6206607 12.3523997 15.3717927 16.6627219 19.4947174 23.5817581 12.2051925 25.9341578 14.4540605'
          ></polygon>
        </g>
      </g>
    </svg>
  );
}
