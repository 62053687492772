import React from 'react';
import classNames from 'classnames/bind';
import type { IconProps, InputFieldProps } from '../../atoms';
import { Icon, InputField } from '../../atoms';
import style from './Input.module.scss';

const cx = classNames.bind(style);

type InputProps = InputFieldProps &
  IconProps & {
    className?: string;
    testId?: string;
  };

export function Input({ ...props }: InputProps): JSX.Element {
  const InputClasses = cx({ Input: true }, props.className);

  return (
    <div className={InputClasses} test-id={props.testId}>
      <Icon icon={props.icon} size={props.size} color={props.color} />
      <InputField
        type={props.type}
        onChange={props.onChange}
        placeholder={props.placeholder}
        inputMode={props.inputMode}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
      />
    </div>
  );
}
