import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { SponsorService } from '../../../../services';
import type { Sponsor } from '../../../../types';
import { Button, Icon } from '../../../atoms';
import { Input } from '../../../molecules';
import { NavigationDrawer } from '../../../organisms';
import style from './EditSponsor.module.scss';

const cx = classNames.bind(style);
export function EditSponsor(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  const [editSponsor, setEditSponsor] = useState<Sponsor>({
    name: '',
    description: '',
    picture: '',
  });

  useEffect(() => {
    if (params.id) {
      SponsorService.getSponsorById(params.id).then((response) => {
        if (response.error) {
          toast.error('Sponsor konnte nicht geladen werden!');

          return;
        }
        setEditSponsor(response.data);
      });
    }
  }, []);

  const updateSponsor = () => {
    SponsorService.updateSponsor(editSponsor).then((res) => {
      if (res.error) {
        toast.error('Sponsor konnte nicht gespeichert werden!');

        return;
      }

      toast.success('Sponsor wurde erfolgreich gespeichert!');
      navigate('..');
    });
  };

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const sponsorBoxClasses = cx({ SponsorBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses}>
          <Icon icon='premium-check' size={70}></Icon>
          <span>
            <h1>Sponsor bearbeiten</h1>
            <div></div>
          </span>
        </div>

        <div className={sponsorBoxClasses}>
          <div>
            <div>Name:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Name'
                defaultValue={editSponsor.name}
                onChange={(event) => {
                  setEditSponsor({ ...editSponsor, name: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Beschreibung:</div>
            <div>
              <textarea
                rows={20}
                style={{ width: '100%' }}
                value={editSponsor.description}
                onChange={(event) => setEditSponsor({ ...editSponsor, description: event.target.value })}
              ></textarea>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: '5rem' }}>
          <Button type='primary' onClick={updateSponsor}>
            Sponsor speichern
          </Button>
        </div>
      </div>
    </div>
  );
}
