import React from 'react';
import { AccountPlus } from './Icons/AccountPlus';
import { Calendar } from './Icons/Calendar';
import { Clock } from './Icons/Clock';
import { Document } from './Icons/Document';
import { Grid } from './Icons/Grid';
import { Kontakt } from './Icons/Kontakt';
import { Logout } from './Icons/Logout';
import { Magnify } from './Icons/Magnify';
import { PremiumCheck } from './Icons/PremiumCheck';
import { User } from './Icons/User';
import { IconDefaults } from './IconDefaults';

export type IconTypes =
  | 'account-plus'
  | 'user'
  | 'document'
  | 'kontakt'
  | 'clock'
  | 'calendar'
  | 'grid'
  | 'magnify'
  | 'logout'
  | 'premium-check'
  | 'none';

export type IconProps = {
  icon: IconTypes;
  size?: number;
  color?: string;
};

export function Icon({ icon, size = IconDefaults.size, color = IconDefaults.color }: IconProps): JSX.Element {
  switch (icon) {
    case 'account-plus':
      return <AccountPlus size={size} color={color} />;
    case 'user':
      return <User size={size} color={color} />;
    case 'kontakt':
      return <Kontakt size={size} color={color} />;
    case 'clock':
      return <Clock size={size} color={color} />;
    case 'document':
      return <Document size={size} color={color} />;
    case 'calendar':
      return <Calendar size={size} color={color} />;
    case 'grid':
      return <Grid size={size} color={color} />;
    case 'magnify':
      return <Magnify size={size} color={color} />;
    case 'logout':
      return <Logout size={size} color={color} />;
    case 'premium-check':
      return <PremiumCheck size={size} color={color} />;
    case 'none':
      return <></>;
    default:
      return <></>;
  }
}
