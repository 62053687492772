import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { SponsorService } from '../../../services';
import type { Sponsor } from '../../../types';
import { Button, Icon } from '../../atoms';
import { NavigationDrawer } from '../../organisms';
import style from './Sponsors.module.scss';

const cx = classNames.bind(style);

// Example to Save routes for only authenticated users
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

export function Sponsors(): JSX.Element {
  const navigate = useNavigate();

  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  useEffect(() => {
    SponsorService.getAllSponsors().then((response) => {
      if (response.error) {
        toast.error('Sponsoren konnten nicht geladen werden!');

        return;
      }
      const data = response.data as Sponsor[];
      if (data) {
        data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      }
      setSponsors(data);
    });
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const actionBarClasses = cx('flex', 'flex-end', 'mb-2');
  const sponsorBoxClasses = cx({ SponsorBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses}>
          <Icon icon='premium-check' size={70}></Icon>
          <span>
            <h1>Sponsoren</h1>
            <div>Anzahl: {sponsors.length}</div>
          </span>
        </div>
        <div className={actionBarClasses}>
          <div>
            <Button type='primary' onClick={() => navigate('/sponsoren/new')}>
              Sponsor hinzufügen
            </Button>
          </div>
        </div>
        {sponsors.map((sponsor) => (
          <div key={sponsor.id} className={sponsorBoxClasses} onClick={() => navigate(`/sponsoren/${sponsor.id}`)}>
            <div>{sponsor.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
