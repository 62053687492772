import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { EventService, SponsorService } from '../../../../services';
import type { Address, Event, Sponsor } from '../../../../types';
import { Button, Icon } from '../../../atoms';
import { Input } from '../../../molecules';
import { NavigationDrawer } from '../../../organisms';
import style from './NewEvent.module.scss';

const cx = classNames.bind(style);
export function NewEvent(): JSX.Element {
  const navigate = useNavigate();
  const [newEvent, setNewEvent] = useState<Event>({
    title: '',
    slug: '',
    description: '',
    city: '',
    picture: '',
    date: '',
    sponsor: { id: '', name: '', description: '', picture: '' },
  });
  const [eventPicture, setEventPicture] = useState<File>();
  const [address, setAddress] = useState<Address>({ street: '', postalcode: '', city: '' });
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  useEffect(() => {
    SponsorService.getAllSponsors().then((response) => {
      if (response.error) {
        toast.error('Sponsoren konnten nicht geladen werden!');

        return;
      }
      const data = response.data as Sponsor[];
      if (data) {
        data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      }
      setSponsors(data);
    });
  }, []);

  const createEvent = () => {
    const formData = new FormData();

    formData.append('file', eventPicture as Blob);
    EventService.uploadEventPicture(formData).then((response) => {
      if (response.error) {
        toast.error('Upload des Eventbildes fehlgeschlagen!');

        return;
      }
      newEvent.picture = response.data.filePath;
      console.log(newEvent);
      console.log(response.data);
      console.log(newEvent);
      EventService.createEvent({ ...newEvent, address }).then((res) => {
        if (res.error) {
          toast.error('Event konnte nicht erstellt werden!');

          return;
        }

        toast.success('Event wurde erfolgreich erstellt!');
        navigate('/events');
      });
    });
  };

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const eventBoxClasses = cx({ EventBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses}>
          <Icon icon='kontakt' size={70}></Icon>
          <span>
            <h1>Neues Event</h1>
            <div></div>
          </span>
        </div>

        <div className={eventBoxClasses}>
          <div>
            <div>Titel:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Titel'
                onChange={(event) => {
                  setNewEvent({ ...newEvent, title: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Slug:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Slug'
                onChange={(event) => {
                  setNewEvent({ ...newEvent, slug: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Stadt:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Stadt'
                onChange={(event) => {
                  setNewEvent({ ...newEvent, city: event.target.value.toLowerCase() });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Bild:</div>
            <div>
              <Input
                icon={'none'}
                type='file'
                placeholder='Bild'
                onChange={(event) => {
                  if (event.target.files) {
                    setEventPicture(event.target.files[0]);
                  }
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Datum:</div>
            <div>
              <Input
                icon={'none'}
                type='datetime-local'
                placeholder='Datum'
                onChange={(event) => {
                  setNewEvent({ ...newEvent, date: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Sponsor:</div>
            <div style={{ marginTop: '1rem' }}>
              <select
                style={{ width: '100%', padding: '0.75rem', borderRadius: '4px' }}
                onChange={(event) => {
                  const sponsor = sponsors.find((s) => s.id === event.target.value);
                  if (sponsor) {
                    setNewEvent({ ...newEvent, sponsor });
                  }
                }}
              >
                <option value=''>Bitte wählen</option>
                {sponsors.map((sponsor) => (
                  <option key={sponsor.id} value={sponsor.id}>
                    {sponsor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <div>Beschreibung:</div>
            <div>
              <textarea
                rows={20}
                style={{ width: '100%' }}
                value={newEvent.description}
                onChange={(event) => setNewEvent({ ...newEvent, description: event.target.value })}
              ></textarea>
            </div>
          </div>
        </div>

        <div className={eventBoxClasses}>
          <div>
            <div>Straße:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Straße'
                onChange={(event) => {
                  setAddress({ ...address, street: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Postleitzahl:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Postleitzahl'
                onChange={(event) => {
                  setAddress({ ...address, postalcode: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Stadt:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Stadt'
                onChange={(event) => {
                  setAddress({ ...address, city: event.target.value });
                }}
              ></Input>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: '5rem' }}>
          <Button type='primary' onClick={createEvent}>
            Event erstellen
          </Button>
        </div>
      </div>
    </div>
  );
}
