import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { EventService, SponsorService } from '../../../../services';
import type { Address, Event, Sponsor } from '../../../../types';
import { Button, Icon } from '../../../atoms';
import { Input } from '../../../molecules';
import { NavigationDrawer } from '../../../organisms';
import style from './EditEvent.module.scss';

const cx = classNames.bind(style);
export function EditEvent(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();

  const [editEvent, setEditEvent] = useState<Event>({
    title: '',
    slug: '',
    description: '',
    city: '',
    picture: '',
    date: '',
    sponsor: { id: '', name: '', description: '', picture: '' },
  });
  const [address, setAddress] = useState<Address>({ street: '', postalcode: '', city: '' });
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  const getDatetimeLocalString = (date: Date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (params.id) {
      EventService.getEventById(params.id).then((response) => {
        if (response.error) {
          toast.error('Event konnte nicht geladen werden!');

          navigate('/events');

          return;
        }
        setEditEvent(response.data);
      });

      EventService.getEventAddressByEventId(params.id).then((response) => {
        if (response.error) {
          toast.error('Event Adresse konnte nicht geladen werden!');

          return;
        }
        setAddress(response.data);
      });
    }
    SponsorService.getAllSponsors().then((response) => {
      if (response.error) {
        toast.error('Sponsoren konnten nicht geladen werden!');

        return;
      }
      const data = response.data as Sponsor[];
      if (data) {
        data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
      }
      setSponsors(data);
    });
  }, []);

  const updateEvent = () => {
    EventService.updateEvent({ ...editEvent, address }).then((res) => {
      if (res.error) {
        toast.error('Event konnte nicht gespeichert werden!');

        return;
      }

      toast.success('Event wurde erfolgreich bearbeitet!');
      navigate(`/events/${editEvent.id}`);
    });
  };

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const eventBoxClasses = cx({ EventBox: true });

  return (
    <div className={pageClasses}>
      <NavigationDrawer />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={pageHeaderClasses}>
          <Icon icon='kontakt' size={70}></Icon>
          <span>
            <h1>Event bearbeiten</h1>
            <div></div>
          </span>
        </div>

        <div className={eventBoxClasses}>
          <div>
            <div>Titel:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Titel'
                defaultValue={editEvent.title}
                onChange={(event) => {
                  setEditEvent({ ...editEvent, title: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Slug:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Slug'
                defaultValue={editEvent.slug}
                onChange={(event) => {
                  setEditEvent({ ...editEvent, slug: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Stadt:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Stadt'
                defaultValue={
                  editEvent.city && (editEvent.city[0].toUpperCase() + editEvent.city.slice(1)).replaceAll('ue', 'ü')
                }
                onChange={(event) => {
                  setEditEvent({ ...editEvent, city: event.target.value.toLowerCase() });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Datum:</div>
            <div>
              <Input
                icon={'none'}
                type='datetime-local'
                placeholder='Datum'
                defaultValue={editEvent.date && getDatetimeLocalString(new Date(editEvent.date))}
                onChange={(event) => {
                  setEditEvent({ ...editEvent, date: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Sponsor:</div>
            <div style={{ marginTop: '1rem' }}>
              <select
                style={{ width: '100%', padding: '0.75rem', borderRadius: '4px' }}
                value={editEvent.sponsor.id}
                onChange={(event) => {
                  const sponsor = sponsors.find((s) => s.id === event.target.value);
                  if (sponsor) {
                    setEditEvent({ ...editEvent, sponsor });
                  }
                }}
              >
                <option value=''>Bitte wählen</option>
                {sponsors.map((sponsor) => (
                  <option key={sponsor.id} value={sponsor.id}>
                    {sponsor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <div>Beschreibung:</div>
            <div>
              <textarea
                rows={20}
                style={{ width: '100%' }}
                value={editEvent.description}
                onChange={(event) => setEditEvent({ ...editEvent, description: event.target.value })}
              ></textarea>
            </div>
          </div>
        </div>

        <div className={eventBoxClasses}>
          <div>
            <div>Straße:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Straße'
                defaultValue={address.street}
                onChange={(event) => {
                  setAddress({ ...address, street: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Postleitzahl:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Postleitzahl'
                defaultValue={address.postalcode}
                onChange={(event) => {
                  setAddress({ ...address, postalcode: event.target.value });
                }}
              ></Input>
            </div>
          </div>

          <div>
            <div>Stadt:</div>
            <div>
              <Input
                icon={'none'}
                type='text'
                placeholder='Stadt'
                defaultValue={address.city}
                onChange={(event) => {
                  setAddress({ ...address, city: event.target.value });
                }}
              ></Input>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: '5rem' }}>
          <Button type='primary' onClick={updateEvent}>
            Event speichern
          </Button>
        </div>
      </div>
    </div>
  );
}
