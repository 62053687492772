import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { UserService } from '../../../../services';
import type { User } from '../../../../types';
import { Icon, Text } from '../../../atoms';
import { NavigationDrawer } from '../../../organisms';
import style from './User.module.scss';

const cx = classNames.bind(style);

export function User(): JSX.Element {
  const params = useParams();

  const [user, setUser] = useState<User>();

  const getDate = (eventDate: string) => {
    const date = new Date(eventDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    if (params.id) {
      UserService.getUserById(params.id).then((response) => {
        if (response.error) {
          toast.error('User konnte nicht geladen werden!');

          return;
        }
        setUser(response.data);
      });
    }
  }, []);

  const pageClasses = cx('container');
  const pageHeaderClasses = cx('pageHeader');
  const boxHeaderClasses = cx({ BoxHeader: true });
  const boxInnerHeaderClasses = cx({ BoxInnerHeader: true });
  const userBoxClasses = cx({ UserBox: true });

  return (
    <>
      <div className={pageClasses}>
        <NavigationDrawer />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div className={pageHeaderClasses}>
            <Icon icon='user' size={70}></Icon>
            {user && (
              <span>
                <h1>
                  {user.firstname} {user.lastname}
                </h1>
                <div></div>
              </span>
            )}
          </div>
          <div>
            <h2 className={boxHeaderClasses}>Userinfos</h2>
            {user && (
              <div className={userBoxClasses}>
                <div>
                  <span>seit {getDate(user.createdAt)}</span>
                  <span>
                    <span style={{ marginLeft: '1rem' }}>Aktiv: {user.isActivated ? '✅' : '❌'}</span>
                  </span>
                </div>

                <div className={boxInnerHeaderClasses}>E-Mail:</div>
                <div>
                  <Text>{user.email}</Text>
                </div>

                <div className={boxInnerHeaderClasses}>Telefon:</div>
                <div>
                  <Text>{user.phone}</Text>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
